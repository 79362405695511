export default function(element)
{ 
  var currentIndex = 0;

  function rotateToVariant()
  {
    var top = 0;
    for (var i=0; i<currentIndex; i++) {
      top += element.children[i].offsetHeight;
    }
    for (var i=0; i<element.children.length; i++) {
      element.children[i].style.top = -top + 'px';
    }

    //element.style.width = element.children[currentIndex].firstElementChild.offsetWidth + 'px';

    if (currentIndex == element.children.length - 1) {
      setTimeout(function() {
        element.classList.add('disable-animation');
        element.innerHTML = element.innerHTML; // make redraw all elements
        currentIndex = 0;
        rotateToVariant();
        element.classList.remove('disable-animation');
      }, 300);
    }
  }

  rotateToVariant();

  setInterval(function() {
    currentIndex++;
    rotateToVariant();
  }, 2000);
}